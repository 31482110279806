

const Landing = ()=>{

    return(
        <div className="landing backgroundLimes">
            
        </div>
    )
}

export default Landing;